
import React from "react";
import PropTypes from "prop-types";
import "../App.css";

function CardGame({ imageUrl, backgroundColor, text, description, link }) {
  return (
    <div className="card">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div
          className="card-image"
          style={{ backgroundImage: `url(${imageUrl})`, backgroundColor: backgroundColor }}
        ></div>
        <div className="card-body">
          <p className="card-content">{text && text}</p>
        </div>
      </a>

      {description && <div className="card-description">{description}</div>}
    </div>
  );
}

CardGame.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default CardGame;
