import React from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import './App.css';
import CardGame from "./components/CardGame";

function App() {
  return (
    <Router>
      <div style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/battleground_back.png)`,
        backgroundRepeat: 'repeat-x'
      }}
        class="battleground"></div>
      <header>
        <div class="logo">
          <p>
            Kvinter<br />games
          </p>
        </div>
        <div class="socials">
          <a class="itch" href="https://mrkvinter.itch.io/" target="_blank">
            <img src={process.env.PUBLIC_URL + "/images/itchio-logo.svg"} alt="itch.io" />
          </a>
          <a class="bluesky-logo" href="https://bsky.app/profile/mr-kvinter.bsky.social" target="_blank">
            <img src={process.env.PUBLIC_URL + "/images/bluesky-icon.svg"} alt="X" />
          </a>
          <a class="x-logo" href="https://x.com/MrKvinter" target="_blank">
            <img src={process.env.PUBLIC_URL + "/images/x_logo.svg"} alt="X" />
          </a>
          <a class="tg" href="https://t.me/+zOdRRhXIKrw3OGZi" target="_blank">
            <img src={process.env.PUBLIC_URL + "/images/tg-logo.svg"} alt="Telegram" />
          </a>
        </div>
      </header>

      <div class="section">
        <p>
          For Russian speakers:
        </p>
        <p>
          Подписывайтесь на мой канал в <a href="https://t.me/+zOdRRhXIKrw3OGZi" target="_blank">Telegram</a> для общения и обновлений!
        </p>
      </div>

      <div class="h-divider">
        <div class="shadow"></div>
      </div>

      <main>

        <h1>Games</h1>
        <div className="cards">
          <CardGame
            imageUrl={process.env.PUBLIC_URL + "/images/KingdomOS-cover.png"}
            backgroundColor="#f0f0f0"
            link="https://mrkvinter.itch.io/"
            description="A strategic clicker with retro aesthetics"
          />
          <CardGame
            imageUrl="https://img.itch.zone/aW1nLzE2MjM4MDgyLnBuZw==/315x250%23c/wxvbmc.png"
            backgroundColor="#f0f0f0"
            link="https://mrkvinter.itch.io/auqa-queen"
            description="A dark story in the cards"
          />
        </div>

        <h1>Tools</h1>
        <div className="cards">
          <CardGame
            //imageUrl={itchHelperLogo}
            //in public folder: images/ItchDevHelperLogo.png
            imageUrl={process.env.PUBLIC_URL + "/images/ItchDevHelperLogo.png"}
            backgroundColor="#212529"
            link="/dev/tag-explorer"
            description="Tool for game developers on itch.io"
          />
        </div>
      </main>
      <footer>
        <div>
          <p>Kvinter Games, 2024</p>
        </div>
      </footer>
    </Router>
  );
}

export default App;
